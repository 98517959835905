<template>
  <router-link class="truncate" :to="routeToNavigate">
    <span class="cursor-pointer hover:underline">{{ item.name }} </span>
  </router-link>
</template>

<script>
export default {
  name: 'AsterixColumnRouterLink',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    route: {
      type: Object,
      default: () => {},
    },
    paramName: {
      type: String,
      default: () => '',
    },
    paramValue: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    routeToNavigate() {
      return { name: this.route.name, params: { [this.paramName]: this.item[this.paramValue] } };
    },
  },
};
</script>
